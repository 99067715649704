import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "../helpers";
import { message } from "antd";
import {useTranslation} from "react-i18next";

const name = "post";
const createInitialState = () => {
  return {
    post: null,
    loading: false,
    generating: false,
  };
};

const createExtraActions = () => {

  const baseUrl = `${process.env.REACT_APP_API_URL}`;

  const getPost = () => {
    return createAsyncThunk(
      `${name}/post`,
      async (contentPlanId) =>
        await fetchWrapper.get(`${baseUrl}/post/actual/${contentPlanId}`)
    );
  };

  const generatePost = () => {
    return createAsyncThunk(`${name}/postCreate`, async ({contentPlanId, reqBody}) => {
      return await fetchWrapper.post(
        `${baseUrl}/post/generate/${contentPlanId}`,reqBody
      );
    });
  };

  const generateImage = () => {
    return createAsyncThunk(
      `${name}/generateImage`,
      async ({ postId, contentPlanId }) => {
        await fetchWrapper.post(`${baseUrl}/post/generate-image/${postId}`);
        return await fetchWrapper.get(
          `${baseUrl}/post/actual/${contentPlanId}`
        );
      }
    );
  };

  const removeImage = () => {
    return createAsyncThunk(
        `${name}/removeImage`,
        async ({ postId, contentPlanId }) => {
          await fetchWrapper.delete(`${baseUrl}/post/remove-image/${postId}`);
          return await fetchWrapper.get(
              `${baseUrl}/post/actual/${contentPlanId}`
          );
        }
    );
  };

  const uploadImage = () => {
    return createAsyncThunk(
      `${name}/uploadImage`,
      async ({ postId, contentPlanId, images }) => {
        const messages = {
          success: {
            ru: "Изображение успешно загружено",
            en: "The image has been successfully uploaded",
            kk: "Сурет сәтті жүктелді",
          },
          error: {
            ru: "ERROR! Ошибка при загрузке изображения, возможно размер файлов превышает 10мб",
            en: "ERROR! Error when uploading an image, files may be larger than 10mb",
            kk: "ERROR! Суретті жүктеу кезінде қате, мүмкін файл өлшемі 10мб-тан асады",
          },
        };
        const formData = new FormData();
        images.forEach((image) => formData.append("images", image));
        // formData.append("images", images);
        fetchWrapper.post(
          `${baseUrl}/post/upload-image/${postId}`,
          formData
        ).then((response)=>{
          if (response){
            message.success(messages.success[localStorage.getItem('ln')] || messages.success.en);
          }else {
            message.error(messages.error[localStorage.getItem('ln')] || messages.error.en);
          }
        }).catch((err)=>{
          message.error(messages.error[localStorage.getItem('ln')] || messages.error.en);
          console.error(err);
        });

        return await fetchWrapper.get(
          `${baseUrl}/post/actual/${contentPlanId}`
        );
      }
    );
  };

  const uploadVideo = () => {

    return createAsyncThunk(
        `${name}/uploadImage`,
        async ({ postId, contentPlanId, video }) => {
          const messages = {
            success: {
              ru: "Видео успешно загружено",
              en: "Video successfully uploaded",
              kk: "Бейне сәтті жүктелді",
            },
            error: {
              ru: "ERROR! Ошибка при загрузке видео, возможно размер файлов превышает 10мб либо продолжительность видео превышает 30 секунд",
              en: "ERROR! Error when uploading video, maybe file size exceeds 10mb or video duration exceeds 30 seconds",
              kk: "ERROR! Бейнені жүктеу кезінде қате, мүмкін файлдардың өлшемі 10 Мб-тан асады немесе бейненің ұзақтығы 30 секундтан асады",
            },
          };
          const formData = new FormData();
          // images.forEach((image) => formData.append("images", image));
          formData.append("video", video);
          await fetchWrapper.post(
              `${baseUrl}/post/upload-video/${postId}`,
              formData
          ).then((response)=>{
            if (response){
              message.success(messages.success[localStorage.getItem('ln')] || messages.success.en);
            }else {
              message.error(messages.error[localStorage.getItem('ln')] || messages.error.en);
            }
          }).catch((err)=>{
            message.error(messages.error[localStorage.getItem('ln')] || messages.error.en);
            console.error(err);
          });

          return await fetchWrapper.get(
              `${baseUrl}/post/actual/${contentPlanId}`
          );
        }
    );
  };
  const removeVideo = () => {
    return createAsyncThunk(
        `${name}/removeVideo`,
        async ({ postId, contentPlanId }) => {
          await fetchWrapper.delete(`${baseUrl}/post/remove-video/${postId}`);
          return await fetchWrapper.get(
              `${baseUrl}/post/actual/${contentPlanId}`
          );
        }
    );
  };



  return {
    getPost: getPost(),
    generatePost: generatePost(),
    generateImage: generateImage(),
    uploadImage: uploadImage(),
    uploadVideo: uploadVideo(),
    removeVideo: removeVideo(),
    removeImage: removeImage()
  };
};

const createExtraReducers = (builder) => {
  const getPost = () => {
    const { pending, fulfilled, rejected } = extraActions.getPost;
    builder.addCase(pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fulfilled, (state, action) => {
      if (action.payload?.status) {
        state.loading = false;
        state.generating = true;
      } else {
        state.post = action.payload;
        state.loading = false;
        state.generating = false;
      }
    });
    builder.addCase(rejected, (state, action) => {
      state.post = null;
      state.loading = false;
    });
  };

  const uploadImagePost = () => {
    const { pending, fulfilled, rejected } = extraActions.uploadImage;
    builder.addCase(pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fulfilled, (state, action) => {
      state.post = action.payload;
      state.loading = false;
    });
    builder.addCase(rejected, (state, action) => {
      state.post = null;
      state.loading = false;
    });
  };

  const generateImagePost = () => {
    const { pending, fulfilled, rejected } = extraActions.generateImage;
    builder.addCase(pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fulfilled, (state, action) => {
      state.post = action.payload;
      state.loading = false;
    });
    builder.addCase(rejected, (state, action) => {
      state.post = null;
      state.loading = false;
    });
  };

  const removeImagePost = () => {
    const { pending, fulfilled, rejected } = extraActions.removeImage;
    builder.addCase(pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fulfilled, (state, action) => {
      state.post = action.payload;
      state.loading = false;
    });
    builder.addCase(rejected, (state, action) => {
      state.loading = false;
    });
  };

  const createPost = () => {
    const { pending, fulfilled, rejected } = extraActions.generatePost;
    builder.addCase(pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fulfilled, (state, action) => {
      state.loading = false;
      state.generating = true;
    });
    builder.addCase(rejected, (state, action) => {
      state.loading = false;
    });
  };

  getPost();
  createPost();
  uploadImagePost();
  generateImagePost();
  removeImagePost();
};

const extraActions = createExtraActions();
const slice = createSlice({
  name: name,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    const extraReducers = createExtraReducers(builder);
  },
});

export const postActions = { ...slice.actions, ...extraActions };
export const postReducer = slice.reducer;
