import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "../helpers";
import { store } from "../store";
import { message } from "antd";
import { getI18n } from "react-i18next";
import axios from "axios";

const name = "contentPlan";

const createInitialState = () => {
  return {
    socialNetworks: [
      {
        generate: "instagram",
        fetch: "Instagram",
      },
      {
        generate: "facebook",
        fetch: "Facebook",
      },
      {
        generate: "linkedin",
        fetch: "LinkedIn",
      },
    ],
    contentPlans: {
      Instagram: [],
      Facebook: [],
      LinkedIn: [],
    },
    contentPlan: {},
    weeks: [1],
    loading: true,
    loadingCreate: false,
    generating: false,
    leadingQuestionsLoading: false,
  };
};

const createExtraActions = () => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;

  const getContentPlan = () => {
    return createAsyncThunk(`${name}/contentPlan`, async (networks) => {
      const requests = await Promise.all(
        networks.map(
          async (network) =>
            await fetchWrapper.get(
              `${baseUrl}/content-plan/actual/${network.fetch}`
            )
        )
      );
      return requests;
    });
  };

  const getContentPlanById = () => {
    return createAsyncThunk(
      `${name}/contentPlanById`,
      async (id) => await fetchWrapper.get(`${baseUrl}/content-plan/${id}`)
    );
  };

  const createContentPlan = () => {
    return createAsyncThunk(
      `${name}/createContentPlan`,
      async ({ socialNetwork, content_blocks, frequency, timezone, lang, week }) =>
        await fetchWrapper.post(
          `${baseUrl}/content-plan/generate/${socialNetwork}`,
          { contentBlocks: content_blocks, frequency, timezone, lang, week }
        )
    );
  };

  const createContentPlanDetails = () => {
    return createAsyncThunk(
      `${name}/createContentPlanDetails`,
      async ({ id, details, formatId, selectedContentLangs, postLength, postEmojis }) => {
        await fetchWrapper.post(`${baseUrl}/content-plan/details/${id}`, {
          details,
          formatId,
          selectedContentLangs,
          postLength,
          postEmojis,
        });
      }
    );
  };

  const generateLeadingQuestions = () => {
    return createAsyncThunk(
      `${name}/generateLeadingQuestion`,
      async (id, { rejectWithValue }) => {
        try {
          const token = await store.getState().auth.user?.accessToken;
          await axios.get(`${baseUrl}/content-plan/leading-questions/${id}`, {
            headers: {
              'accessToken': `${token}`,
            },
          });
          return await fetchWrapper.get(`${baseUrl}/content-plan/${id}`);
        } catch (error) {
          return rejectWithValue(error?.response?.data);
        }
      }
    );
  };

  return {
    getContentPlan: getContentPlan(),
    createContentPlan: createContentPlan(),
    getContentPlanById: getContentPlanById(),
    generateLeadingQuestions: generateLeadingQuestions(),
    createContentPlanDetails: createContentPlanDetails(),
  };
};

const createExtraReducers = (builder) => {
  const getContentPlan = () => {
    const { pending, fulfilled, rejected } = extraActions.getContentPlan;
    builder.addCase(pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fulfilled, (state, action) => {
      if (action.payload[0]?.status) {
        state.loading = false;
        state.generating = true;
      } else {
        const weeks = new Set([1]);
        state.socialNetworks.forEach((sn, index) => {
          state.contentPlans[sn.fetch] = action.payload[index];
          for (const cn of action.payload[index]) {
            weeks.add(cn.week);
          }
        });
        state.loading = false;
        state.weeks = Array.from(weeks);
        state.generating = false;
      }
    });
    builder.addCase(rejected, (state, action) => {
      state.loading = false;
    });
  };

  const createContentPlan = () => {
    const { pending, fulfilled, rejected } = extraActions.createContentPlan;
    builder.addCase(pending, (state) => {
      state.loadingCreate = true;
    });
    builder.addCase(fulfilled, (state, action) => {
      state.loadingCreate = false;
      state.generating = true;
    });
    builder.addCase(rejected, (state, action) => {
      state.loadingCreate = false;
      throw false;
    });
  };

  const getContentPlanById = () => {
    const { pending, fulfilled, rejected } = extraActions.getContentPlanById;
    builder.addCase(pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fulfilled, (state, action) => {
      state.loading = false;
      state.contentPlan = action.payload;
    });
    builder.addCase(rejected, (state, action) => {
      state.loading = false;
    });
  };

  const generateLeadingQuestions = () => {
    const { pending, fulfilled, rejected } =
      extraActions.generateLeadingQuestions;
    builder.addCase(pending, (state) => {
      state.leadingQuestionsLoading = true;
    });
    builder.addCase(fulfilled, (state, action) => {
      state.leadingQuestionsLoading = false;
      state.contentPlan = action.payload;
    });
    builder.addCase(rejected, (state, action) => {
      state.leadingQuestionsLoading = false;
      message.error(
        getI18n().t("functions.limitReached", {
          count: action?.payload?.count,
          max: action?.payload?.max,
        })
      );
      // if(action.payload?.error){
      //     message.error(`${action.payload?.error} 12`)
      // } else {

      // }
    });
  };

  const createContentPlanDetails = () => {
    const { pending, fulfilled, rejected } =
      extraActions.createContentPlanDetails;
    builder.addCase(pending, (state) => {
      state.loadingCreate = true;
    });
    builder.addCase(fulfilled, (state, action) => {
      state.loadingCreate = false;
      state.generating = true;
    });
    builder.addCase(rejected, (state, action) => {
      state.loadingCreate = false;
    });
  };

  getContentPlan();
  getContentPlanById();
  generateLeadingQuestions();
  createContentPlan();
  createContentPlanDetails();
};

const extraActions = createExtraActions();
const slice = createSlice({
  name: name,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    const extraReducers = createExtraReducers(builder);
  },
});

export const contentPlanActions = { ...slice.actions, ...extraActions };
export const contentPlanReducer = slice.reducer;
