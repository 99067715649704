import React, { useEffect, useState } from "react";
import PageHeader from "../../components/Header/PageHeader";
import { useTranslation } from "react-i18next";
import { fetchWrapper } from "../../helpers";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Link } from "react-router-dom";
import arrowRightIcon from "../icons/arrow-right-content.svg"; // Replace with path to your Google icon
import { PlanCard } from "../ContentPlan/PlanCard";
import "./style.css";
import { Button, notification, Space } from 'antd';
import {useSelector} from "react-redux";
import {jwtDecode} from "jwt-decode";

const baseUrl = `${process.env.REACT_APP_API_URL}`;

export const Posts = () => {
  const [fbPageId, setFbPageId] = useState('');
  const user = useSelector((obj) => obj.auth.user);
  const [localUser, setLocalUser] = useState(
      JSON.parse(localStorage.getItem("user"))
  );
  const [posts, setPosts] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [notificationApi, notificationContextHolder] = notification.useNotification();
  const [strategy, setStrategy] = useState(false)
  const [token, setToken] = useState("")
  const openNotification = () => {
      const key = `open${Date.now()}`;
      const btn = (
        <Space>
          <Button type="primary" size="small" onClick={() => navigate("/personal-brand")}>
            {t("functions.generate")}
          </Button>
        </Space>
      );
      notificationApi.open({
        message: t("functions.error"),
        description: t("contentPlan.strategyError"),
        btn,
        key,
      });
    };

    const openNotificationLinkedIn = () => {
      const key = `open${Date.now()}`;
      const btn = (
        <Space>
          <Button type="primary" size="small" onClick={() => navigate("/profile")}>
            {t("profile.linkAccount")}
          </Button>
        </Space>
      );
      notificationApi.open({
        message: t("contentPlan.tokenError"),
        btn,
        key,
      });
    };

    const submit = () => {
      if(token === ""){
          openNotificationLinkedIn()
      // }else if(strategy === false){
      //     openNotification()
      }else{
        navigate("/generate-post")
      }
  }

  useEffect(() => {
    fetchWrapper.get(`${baseUrl}/profile/`).then((res) => {
      setFbPageId(res.facebookPageId);
    });
    fetchWrapper.get(`${baseUrl}/post/all`).then((res) => {
      setPosts(res);
    });

    fetchWrapper.get(`${baseUrl}/strategy/actual/`).then(res => {
      if(res[0] != null){
          setStrategy(true)
      }
    })
    fetchWrapper.get(`${baseUrl}/auth/getSocialMediaTokens/`).then(res => {
      res.forEach((t) => {
          if(t.socialMediaType == "LINKEDIN"){
            setToken(t.accessToken)
          }
          if(t.socialMediaType == "FACEBOOK"){
            setToken(t.accessToken)
          }
          if(t.socialMediaType == "INSTAGRAM"){
            setToken(t.accessToken)
          }
      })
  })
  }, []);

  return (
    <>
      {notificationContextHolder}
      <PageHeader
        title={t("posts.header")}
        onBackClick={() => {
          navigate("/home");
        }}
      />

      {localUser.strategiesCount ?
          <div className='flex justify-center items-center mt-5'>
            <button
                className="logout-button max-w-fit max-w-max mx-auto mb-2.5"
                onClick={() => submit()}
            >
              {t("home.createPost")}
            </button>
          </div>
          :
          <div className="flex flex-col items-center justify-center">
            <h3 className='text-xl text-center font-semibold mb-10'>
              {t("post.needSurveyText")}
            </h3>
            <button
                className="survey-button"
                onClick={() => navigate("/survey")}
            >
              {t("contentPlan.goToSurveyBtnText")}
            </button>
          </div>
      }


      <div className="posts">
        {/* {posts.map((post) => (
              <div className="post-card">
                  <p className="post-card__text">{post.post}</p>
                  <p className="post-card__hashtags">{post.hashtags}</p>
              </div>
          ))} */}

        {posts.map((post, index) => (
            <PlanCard
                key={index}
                item={{...post.ContentPlan, id: post.ContentPlan.id}}
                index={index}
                fbPageId={fbPageId}
            />
        ))}
      </div>

    </>
  );
};
