import React, { useEffect, useState } from 'react';
import './ContentPlanDrawer.css'; // CSS file name
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { fetchWrapper } from '../../helpers';
import {message} from 'antd'
import { Button, notification, Space } from 'antd';
import { useNavigate } from "react-router-dom";

const baseUrl = `${process.env.REACT_APP_API_URL}`;

function ContentPlanDrawer({ open, close, onOk, loadingCreate, activeWeek, socialNetworks, handleChangeSocialNetworks, currentWeekSnCreated }) {
    const [localUser, setLocalUser] = useState(
        JSON.parse(localStorage.getItem("user"))
    );
    const {t} = useTranslation()
    const [toggles, setToggles] = useState({});
    const [frequency, setFrequency] = useState(7);
    const [strategy, setStrategy] = useState(false)
    const [messageApi, contextHolder] = message.useMessage();
    const [notificationApi, notificationContextHolder] = notification.useNotification();
    const navigate = useNavigate();

    const openNotification = () => {
        const key = `open${Date.now()}`;
        const btn = (
          <Space>
            <Button type="primary" size="small" onClick={() => navigate("/personal-brand")}>
              {t("functions.generate")}
            </Button>
          </Space>
        );
        notificationApi.open({
          message: t("functions.error"),
          description: t("contentPlan.strategyError"),
          btn,
          key,
          onClose: close,
        });
      };

    const openNotificationLinkedIn = () => {
        const key = `open${Date.now()}`;
        const btn = (
          <Space>
            <Button type="primary" size="small" onClick={() => navigate("/profile")}>
              {t("profile.linkAccount")}
            </Button>
          </Space>
        );
        notificationApi.open({
          message: t("contentPlan.tokenError"),
          btn,
          key,
          onClose: close,
        });
      };

    const blocks = useSelector(obj => obj.strategy.blocks)

    const handleToggle = (key) => {
        setToggles({ ...toggles, [key]: !toggles[key] });
    };

    const submit = () => {
        if(!socialNetworks){
            openNotificationLinkedIn()
        // }else if(strategy === false){
        //     openNotification()
        }else{
            const filteredToogles = Object.keys(toggles).filter(i => toggles[i])
            onOk({ contentBlocks: filteredToogles, socialNetworks: socialNetworks.split(','), frequency })
        }
    }

    useEffect(() => {
        fetchWrapper.get(`${baseUrl}/strategy/actual/`).then(res => {
            if(res[0] != null){
                setStrategy(true)
            }
        })
    }, [])

    return (
        <>
        {contextHolder}
        {notificationContextHolder}
        <Modal onCancel={close} open={open} okButtonProps={{ style: { visibility: 'hidden' } }} cancelButtonProps={{ style: { visibility: 'hidden' } }}>
            <div className="content-plan-settings">
                <div
                    className="flex gap-4 justify-center py-4 border-b border-solid border-b-[color:var(--Primary-200,#E9D7FE)]">
                    {!currentWeekSnCreated.linkedin &&
                        <div style={{opacity: socialNetworks?.includes("LinkedIn") ? 1 : 0.4}}
                             onClick={() => handleChangeSocialNetworks("LinkedIn")}
                             className="flex flex-col flex-1 cursor-pointer">
                            <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/d25408c5dff59b86cb5e75674b2a798a87cd455bde0adf0ceb8c9a670d1405e8?apiKey=300ff06bbf3a4f5c9c73ca7577720bf1&"
                                className="self-center w-6 aspect-square"
                            />
                            <div className="mt-1">LinkedIn</div>
                        </div>
                    }
                    {!currentWeekSnCreated.facebook &&
                        <div style={{opacity: socialNetworks?.includes("Facebook") ? 1 : 0.4}}
                             onClick={() => handleChangeSocialNetworks("Facebook")}
                             className="flex flex-col flex-1 cursor-pointer">
                            <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/f8432315b7e3752ab28ffd9571f771342eef6b676c1f20e4b8a5e6ee48c70349?apiKey=300ff06bbf3a4f5c9c73ca7577720bf1&"
                                className="self-center w-6 aspect-square"
                            />
                            <div className="mt-1">Facebook</div>
                        </div>
                    }
                    {!currentWeekSnCreated.instagram &&
                        <div style={{opacity: socialNetworks?.includes("Instagram") ? 1 : 0.4}}
                             onClick={() => handleChangeSocialNetworks("Instagram")}
                             className="flex flex-col flex-1 cursor-pointer">
                            <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/497038a4df4615b7ef35db19c49364c0fdcd7e3749f9681fe807b55de2426e88?apiKey=300ff06bbf3a4f5c9c73ca7577720bf1&"
                                className="self-center w-6 aspect-square"
                            />
                            <div className="mt-1">Instagram</div>
                        </div>
                    }
                </div>

                {/* <div className="frequency-section">
                    <div>
                        <label htmlFor="frequency-range">{t('contentPlan.frequency')}: {frequency}</label>
                    </div>
                    <input value={frequency} onChange={e => setFrequency(e.target.value)} type="range" id="frequency-range" className="frequency-range" min="1" max="10" />
                </div> */}

                {/*<div className="elements-section">*/}
                {/*    <div className="elements-title">{t('contentPlan.elementsTitle')}</div>*/}
                {/*    {blocks.map((block) => (*/}
                {/*        <div className="toggle-option" key={block}>*/}
                {/*            <div className="option-label">{block}</div>*/}
                {/*            <div className={`toggle-switch ${toggles[block] ? 'active' : ''}`} onClick={() => handleToggle(block)}>*/}
                {/*                <div className="toggle-knob"></div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    ))}*/}
                {/*</div>*/}

                {/* <div className="input-field">
                    <input className='justify-center self-stretch py-2 pr-9 pl-3 text-base leading-6 text-gray-500 whitespace-nowrap bg-white rounded-lg border border-solid shadow-sm border-[color:var(--Gray-300,#D0D5DD)]'
                        type="text" placeholder={t('contentPlan.custom')} />
                </div> */}

                {
                    (localUser?.strategiesCount < 5) || (activeWeek === 3 && localUser?.strategiesCount < 15) ?
                        <h3 className='text-xl text-center font-semibold mb-10'>
                            {t("contentPlan.needSurveyText")}
                        </h3>
                        :
                        <button className="logout-button disabled:opacity-50 disabled:cursor-not-allowed"
                                disabled={loadingCreate || !socialNetworks}
                                onClick={submit}>{loadingCreate ? t('functions.loading') : t('functions.generate')}</button>

                }

                <button  className="close-button " onClick={close}>{t('functions.close')}</button>
            </div>
        </Modal>
        </>
    );
}

export default ContentPlanDrawer;
