import React, {useRef} from 'react';
import {useOrganization} from "../../context/organizationContext";
import {useTranslation} from "react-i18next";

const OrganizationLogo = () => {
	const { t } = useTranslation();
	const inputRef = useRef(null);
	const {organization, setOrganizationLogo} = useOrganization();

	const uploadLogo = (e) => {
		const file = e.target.files[0];
		setOrganizationLogo(file);
	}

	if (!organization?.id){
		return null
	}
	
	if (organization?.curator){
		return (
				<div className='relative group mb-2.5 pb-4 border-b'
						 onClick={() => inputRef.current.click()}
				>
					<p className='transition-opacity opacity-0 group-hover:opacity-100 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full text-center font-semibold text-violet-700 hover:cursor-pointer z-[1]'>
						{t('organization.replaceLogo')}
					</p>
					<img className='max-w-32 max-h-32 aspect-square object-contain mx-auto hover:cursor-pointer group-hover:brightness-150 group-hover:opacity-50 group-hover:saturate-0' src={organization?.logoUrl} alt=""/>
					<input onChange={uploadLogo} accept='image/*' ref={inputRef} className='hidden' type="file"/>
				</div>
		);
	} else {
		return (
				<div className='mb-2.5 pb-4 border-b'>
					<img className='max-w-32 max-h-32 aspect-square object-contain mx-auto' src={organization?.logoUrl} alt=""/>
				</div>
		);
	}
};

export default OrganizationLogo;