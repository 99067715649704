import {useEffect, useState} from "react";
import {fetchWrapper} from "../../helpers";
import {message} from "antd";
import {useTranslation} from "react-i18next";


const PromoCodeForm = () => {
	const {t} = useTranslation();
	const [code, setCode] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmitForm = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		if (!code) return;
		let response = undefined;
		try {
			response = await fetchWrapper.post(`${process.env.REACT_APP_API_URL}/auth/promo`, {
				code,
			})
		} catch(err) {
			console.error(err)
		}
		if (response?.message) {
			setCode('')
			window.location.reload();
		} else {
			message.error(t("subscriptionPlans.promoCodeError"));
		}

		setIsLoading(false);
	};

	return (
			<form onSubmit={handleSubmitForm}>
				<label >
					<span className='text-sm'>
					{t("subscriptionPlans.promoCodeTitle")}
					</span>
				<input
						className='w-full border rounded-lg p-2'
						type="text"
						placeholder={t("subscriptionPlans.havePromoCode")}
						onChange={e=> setCode(e.target.value)}
				/>
				</label>
				<button
						disabled={!code || isLoading}
						className='logout-button disabled:opacity-50 disabled:cursor-not-allowed'
				>
					{t("functions.apply")}
				</button>
			</form>
	);
};

export default PromoCodeForm;