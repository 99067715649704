import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { store } from './store';
import { SocketContext, socket } from './context/socketContext';
import OrganizationContext from "./context/organizationContext";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <SocketContext.Provider value={socket}>
      <Provider store={store}>
        <OrganizationContext>
          {/* <React.StrictMode> */}
          <App />
          {/* </React.StrictMode> */}
        </OrganizationContext>
      </Provider>
    </SocketContext.Provider>
  </BrowserRouter>
);
