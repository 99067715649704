export function getUserCoordinates() {
	return new Promise((resolve, reject) => {

		if (!navigator.geolocation) {
			reject("Geolocation API is not supported by your browser.");
			return;
		}

		navigator.geolocation.getCurrentPosition(
				(position) => {
					const { latitude, longitude } = position.coords;
					resolve( [latitude, longitude] );
				},
				(error) => {
					reject(`Error getting location: ${error.message}`);
				},
				{ enableHighAccuracy: true }
		);
	});
}