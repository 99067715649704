import React, {useRef} from "react";
import {useOrganization} from "../../context/organizationContext";
import {useTranslation} from "react-i18next";
import EndDateTimer from "../Timers/EndDateTimer";
import OrganizationHelpers from "./OrganizationHelpers";


const OrganizationSettings = () => {
	const { t } = useTranslation();
	const {organization, setOrganizationFile} = useOrganization();
	const fileInputRef = useRef(null);

	const uploadFile = (e) => {
		const file = e.target.files[0];
		setOrganizationFile(file);
	}

	if (!organization?.id){
		return null
	}

	return (
			<div className='border-t mt-2.5 pt-2.5'>
				<p className='text-xs text-center'>
					{t('organization.managedByOrganization')}
				</p>
				<h5 className='text-center text-xl font-semibold my-2'>{organization?.name}</h5>

				{
					organization?.endDate &&(
							<>
								<p className='text-xs text-center'>{t("profile.endSubscriptionDate")}</p>
								<p className='text-xs text-center'>
									<EndDateTimer endDate={organization?.endDate} currentDate={new Date()} />
								</p>
							</>
						)
				}
				<span className='block border-t my-2.5'/>
				{organization?.curator &&
						<>
							<a
									className='block text-xs text-center -mb-1 mt-4 opacity-75 hover:opacity-100 hover:underline'
									href={organization.fileUrl}
									target='_blank'
							>
								{t('organization.viewFile')}
							</a>
							<button
									className='logout-button'
									onClick={() => fileInputRef.current.click()}
							>
								{organization.fileUrl
										? t('organization.replaceFile')
										: t('organization.uploadFile')
								}
							</button>
							<input
									accept='application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
									onChange={uploadFile} className='hidden' ref={fileInputRef} type='file'/>

						</>
				}

				<OrganizationHelpers organization={organization}/>

			</div>
	);
};

export default OrganizationSettings;