import React, { useState } from 'react';
import './BottomNavContentPlan.css'; // Make sure to link the CSS file
import arrowDownIcon from '../icons/arrow-down.svg'; // Replace with path to your Google icon
import arrowUpIcon from '../icons/arrow-up.svg'; // Replace with path to your Google icon
import { PlanCard } from './PlanCard';


const DropdownContent = ({ icon, color, items, fbPageId }) => {
    const [open, setOpen] = useState(false);

    if (!items?.length) return null;
    return (
        <div className='social-network'>
            <div className='social-network-header cursor-pointer' onClick={() => setOpen(!open)}>
                <img src={icon} />
                <img onClick={() => setOpen(!open)} src={open ? arrowUpIcon : arrowDownIcon} />
            </div>

          {open && items && <hr className='social-network-divider'/>}

          {open && items ? <div className='items-container'>
                {items.map((item, index) => (
                    <PlanCard fbPageId={fbPageId} key={item.id} item={item} index={index}/>
                ))}
            </div> : <></>}
        </div>
    );
};

export default DropdownContent;
